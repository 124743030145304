
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'

// components
import { Item } from '../../storybook/Aside/Item/Item'

interface Props {
  is_mobile?: boolean,
}

export const Languages = ({
  is_mobile = false,
}: Props) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const languages = [
    {
      'language_code': 'en',
      'name': t('English'),
    },
    {
      'language_code': 'ja',
      'name': t('日本語'),
    },
    {
      'language_code': 'zh-hant',
      'name': t('繁體中文'),
    },
    {
      'language_code': 'zh-hans',
      'name': t('简体中文'),
    },
  ]

  return languages.map((language, index) => {
    if (i18n.language !== language['language_code']) {
      const clicked = () => {
        i18n.changeLanguage(language['language_code'])

        navigate(window.location.pathname.replace(/^\/(en|ja|zh-hant|zh-hans)/i, `/${language['language_code']}`) + window.location.search)
      }

      return (
        <Item
          key={ index }
          name={ language['name'] }
          theme='sky'
          action='customize'
          onclick_action={ clicked }
          is_mobile={ is_mobile }
        />
      )
    }
  })
}