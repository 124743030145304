import React from 'react'
import { Link } from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'

export function Index () {
  const { t, i18n } = useTranslation()

  return (
    <section className='p-2.5 lg:p-5'>
      <Link to={`/${i18n.language}/setting/icon`}>
        <div className="cursor-pointer border-t-0 border-b border-solid border-black dark:border-white border-x-4 text-center p-2 mb-4 hover:text-fuchsia-900 dark:hover:text-fuchsia-300 hover:border-fuchsia-900 dark:hover:border-fuchsia-300">
          { t('Icon') }
        </div>
      </Link>
      
      <Link to={`/${i18n.language}/setting/username`}>
        <div className="cursor-pointer border-t-0 border-b border-solid border-black dark:border-white border-x-4 text-center p-2 mb-4 hover:text-fuchsia-900 dark:hover:text-fuchsia-300 hover:border-fuchsia-900 dark:hover:border-fuchsia-300">
          { t('Username') }
        </div>
      </Link>

      <Link to={`/${i18n.language}/setting/password`}>
        <div className="cursor-pointer border-t-0 border-b border-solid border-black dark:border-white border-x-4 text-center p-2 mb-4 hover:text-fuchsia-900 dark:hover:text-fuchsia-300 hover:border-fuchsia-900 dark:hover:border-fuchsia-300">
          { t('Password') }
        </div>
      </Link>
    </section>
  )
}
