
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const skyway_scope: { app: any } = {
  'app': {
    'id': process.env.REACT_APP_SKYWAY_APP_ID,
    'turn': true,
    'actions': ['read'],
    'channels': [
      {
        'id': '*',
        'name': '*',
        'actions': ['write'],
        'members': [
          {
            'id': '*',
            'name': '*',
            'actions': ['write'],
            'publication': {
              'actions': ['write'],
            },
            'subscription': {
              'actions': ['write'],
            },
          },
        ],
        'sfuBots': [
          {
            'actions': ['write'],
            'forwardings': [
              {
                'actions': ['write'],
              },
            ],
          },
        ],
      },
    ],
  },
}
