import React, {
  useEffect,
  useState,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useSearchParams,
} from 'react-router-dom'
import {
  useNavigate,
  Link,
} from 'react-router-dom'

// redux
import { useAppSelector } from '../app/hooks'

import { nowInSec, SkyWayAuthToken, SkyWayContext, SkyWayRoom, SkyWayStreamFactory, uuidV4 } from '@skyway-sdk/room'

// skyway id
import { skyway_scope } from '../skyway_scope'

export function Call () {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // redux
  const user = useAppSelector(state => state.user.user)

  if (!user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <Link
          to={`/${i18n.language}/login`}
          className="text-fuchsia-900 dark:text-fuchsia-300 hover:text-fuchsia-950 dark:hover:text-fuchsia-500 cursor-pointer"
        >
          { t('Please login first') }
        </Link>
      </section>
    )
  }

  // states
  const [state, set_state] = useState<string>('waiting')
  const [friend_id, set_friend_id] = useState<string>()

  // refs
  const video_ref = useRef<HTMLVideoElement>(null)
  const friend_video_ref = useRef<HTMLVideoElement>(null)
  const left_time_ref = useRef<HTMLDivElement>(null)

  const audio_ref = useRef<HTMLAudioElement>(null)
  const friend_audio_ref = useRef<HTMLAudioElement>(null)

  const leave_ref = useRef<HTMLButtonElement>(null)

  const token = new SkyWayAuthToken({
    jti: uuidV4(),
    iat: nowInSec(),
    exp: nowInSec() + 60 * 60 * 24,
    scope: skyway_scope,
  }).encode('u3MkwL3c7PPUVkmYYmkzuovXNKtz+18b1VF+Ka1ntbc=')

  useEffect(() => {
    (async () => {
      const { audio, video } = await SkyWayStreamFactory.createMicrophoneAudioAndCameraStream()

      if (searchParams.get('video') === 'true' && video_ref.current) {
        video.attach(video_ref.current)
        await video_ref.current.play()
      }

      const context = await SkyWayContext.Create(token)
      const room = await SkyWayRoom.FindOrCreate(context, {
        type: 'p2p',
        name: searchParams.get('room_id')??'',
      })

      room.onMemberLeft.once(() => {
        navigate(`/${i18n.language}`)
      })

      room.onMemberJoined.add(() => {
        if (room.members.length > 1) {
          setTimeout(() => {
            data.write(user?.id??'')
            if (state == 'waiting') {
              set_state('started')

              setInterval(() => {
                if (left_time_ref.current) {
                  let minutes = parseInt(left_time_ref.current?.innerHTML.split(':')[0])
                  let seconds = parseInt(left_time_ref.current?.innerHTML.split(':')[1])

                  if (minutes === 0 && seconds === 1) { navigate(`/${i18n.language}`) }

                  if (seconds === 0) {
                    minutes = minutes - 1
                    seconds = 59
                  } else {
                    seconds = seconds - 1
                  }

                  left_time_ref.current.innerHTML = ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2)
                }
              }, 1000)
            }
          }, 2000)
        }
      })

      const me = await room.join()

      if (leave_ref.current) {
        leave_ref.current.onclick = async () => {
          for (const pub of me.publications) {
            await me.unpublish(pub.id)
          }
          await me.leave()
          navigate(`/${i18n.language}`)
        }
      }

      const data = await SkyWayStreamFactory.createDataStream()

      if (searchParams.get('video') === 'true') {
        await me.publish(video)
      }
      await me.publish(audio)
      await me.publish(data)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subscribeAndAttach = (publication: any) => {
        if (publication.publisher.id === me.id) return

        (async () => {
          const { stream } = await me.subscribe(publication.id)

          switch (stream.contentType) {
            case 'video':
              if (friend_video_ref.current) {
                stream.attach(friend_video_ref.current!)
              }
              break;
            case 'audio':
              if (friend_audio_ref.current) {
                stream.attach(friend_audio_ref.current!)
              }
              break;
            case 'data':
              stream.onData.add(data => {
                set_friend_id(data as string)
              })
              break;
            default:
              return;
          }
        })()
      }

      room.publications.forEach(subscribeAndAttach)
      room.onStreamPublished.add((e) => subscribeAndAttach(e.publication))
    })()
  }, [])

  return (
    <section className='p-2.5 lg:p-5'>
      {state === 'waiting' && (
        <div className='text-center mb-4'>{t('Waiting')}</div>
      )}

      {state === 'started' && (
        <>
          <div className='flex flex-row justify-center items-center flex-wrap mb-4'>
            <div className='w-fit'>{t('Left time')}</div>
            <div className='w-fit ml-2' ref={left_time_ref}>10:00</div>
          </div>

          <div className='mb-1 ml-2'>@{friend_id}</div>
        </>
      )}

      <audio
        autoPlay
        className='hidden'
        ref={audio_ref}
      />

      <audio
        autoPlay
        className='hidden'
        ref={friend_audio_ref}
      />

      {searchParams.get('video') === 'true' && (
        <div className='flex flex-col lg:flex-row w-full'>
          <video
            ref={video_ref}
            className='w-full lg:w-1/2 border border-solid border-black'
            muted
            playsInline
          ></video>

          <video
            ref={friend_video_ref}
            className='w-full lg:w-1/2 border border-solid border-black'
            autoPlay
            playsInline
          ></video>
        </div>
      )}

      <button
        className='block w-fit mt-12 mb-8 px-8 py-4 bg-red-800 dark:bg-red-500 hover:bg-red-900 hover:bg-red-400 text-white mx-auto text-center cursor-pointer rounded'
        ref={leave_ref}
      >
        {t('Leave')}
      </button>
    </section>
  )
}
