
import React from 'react'
import { useRef } from 'react'
import {
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import $ from 'jquery'
import { Preferences } from '@capacitor/preferences'
import {
  Routes,
  Route,
} from 'react-router-dom'
import {
  useNavigate,
} from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { SplashScreen } from '@capacitor/splash-screen'

// css
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// helmet
import {
  HelmetProvider
} from 'react-helmet-async'

// redux
import { Provider } from 'react-redux'
import { makeStore, AppStore } from '../app/store'

// components
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import Aside from './Aside'
import AsideMobile from './AsideMobile'

const deviceLanguages = {
  android: {
    en: 'en',
    ja: 'ja',
    cn: 'zh-hans',
    zh: 'zh-hant',
  },
  ios: {
    en: 'en',
    ja: 'ja',
    cn: 'zh-hans',
    zh: 'zh-hant',
  },
}

const Layout = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  CapacitorUpdater.addListener('appReady', () => {
    SplashScreen.hide()
  })

  CapacitorUpdater.notifyAppReady()

  const storeRef = useRef<AppStore>()

  if (!storeRef.current) {
    storeRef.current = makeStore()
  }

  const getDeviceLanguageCode = async () => {
    const code = await Device.getLanguageCode()
    return code.value
  }

  const setDefaultLanguage = async () => {
    let defaultLanguage = 'en'
    const platform = Capacitor.getPlatform()

    if (platform === 'ios' || platform === 'android') {
      const deviceLanguage = await getDeviceLanguageCode()
      if (deviceLanguage in deviceLanguages[platform]) {
        defaultLanguage =
          deviceLanguages[platform][deviceLanguage as 'ja' | 'en' | 'zh']
      }
    }
    navigate(`/${defaultLanguage}`)
    i18n.changeLanguage(defaultLanguage)
  }

  useEffect(() => {
    const pathnameSplitBySlash = window.location.pathname.split('/')
    const hasLanguage = /en|ja|zh-hant|zh-hans/.test(pathnameSplitBySlash[1])
    if (!hasLanguage) setDefaultLanguage()

    const set_theme = async () => {
      const { value } = await Preferences.get({ key: 'theme' })

      if (value === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }

    set_theme()

    $('header').css('opacity', '0')
    $('aside').css('opacity', '0')
    $('main').css('opacity', '0')
    $('footer').css('opacity', '0')

    setTimeout( function () {
      $('header').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 200 )

    setTimeout( function () {
      $('aside').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 400 )

    setTimeout( function () {
      $('main').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 600 )

    setTimeout( function () {
      $('footer').css({
        'transition-duration': '100ms',
        'opacity': '1',
      })
    }, 800 )

    setTimeout( function () { 
      $('main').css(
        'min-height',
        (($(window).innerHeight() || 0) - ($('header').outerHeight() || 0) - ($('footer').outerHeight() || 0) - 4) + 'px'
      )
    }, 200)
  }, [])

  return (
    <HelmetProvider>
      <Provider store={ storeRef.current }>
        <Routes>
          <Route
            path='/:locale/*'
            element={
              <>
                <div className='flex flex-row justify-center m-px gap-x-px min-h-screen text-text-black dark:text-white'>

                  <Aside />
                  <AsideMobile />

                  <div className='flex flex-col w-full lg:w-88/100 flex-grow gap-y-px'>
                    <Header />
                    <Main />
                    <Footer />
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </Provider>
    </HelmetProvider>
  )
}

export default Layout