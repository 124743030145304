import React, {
  useRef,
} from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'

interface Props {
  visible: boolean,
  set_visible: (visibility: boolean) => void,
  image_for_crop: string,
  set_src: (src: string) => void,
  rate_width: number,
  rate_height: number,
}

export const Crop = ({
  visible,
  set_visible,
  image_for_crop,
  set_src,
  rate_width,
  rate_height,
}: Props) => {
  const { t } = useTranslation()

  const image = new Image()

  // refs
  const canvas_ref = useRef(null)
  const range_ref = useRef<HTMLInputElement | null>(null)
  const menu_ref = useRef<HTMLDivElement | null>(null)

  let image_crop_canvas: HTMLCanvasElement = document.querySelector('canvas.image-crop')!

  let x = 0.0
  let y = 0.0
  let v = 1.0
  let mdx = 0.0
  let mdy = 0.0

  let iw = 0
  let ih = 0
  let cw = 0
  let ch = 0
  let crop_width = 0
  let crop_height = 0
  let crop_x = 0
  let max_crop_width = 0
  let max_crop_height = 0

  image.onload = function () {
    image_crop_canvas = canvas_ref.current!
    image_crop_canvas.setAttribute('width', window.innerWidth * 0.97 + 'px')

    const range = range_ref.current

    iw = image.width
    ih = image.height
    cw = image_crop_canvas.width
    ch = ih * cw / iw
    crop_width = cw
    crop_height = (rate_height * crop_width) / rate_width
    crop_x = 0
    max_crop_width = cw
    max_crop_height = window.innerHeight - (menu_ref.current?.clientHeight ?? 0)


    if (max_crop_height < crop_height) {
      crop_width = crop_width * max_crop_height / crop_height
      crop_height = max_crop_height
    }

    if (max_crop_width < crop_width) {
      crop_height = crop_height * max_crop_width / crop_width
      crop_width = max_crop_width
    }

    if (crop_height > ch) {
      crop_width = crop_width * ch / crop_height
      crop_height = ch
    }

    if (crop_width > cw) {
      crop_height = crop_height * cw / crop_width
      crop_width = cw
    }

    if (cw > crop_width) {
      crop_x = (cw - crop_width) / 2
    }

    let isMouseDown = false

    image_crop_canvas.setAttribute("height", ch + "px")

    if (range) {
      $(range).val(1)
    }

    function drawCanvas() {
      const ctx = image_crop_canvas.getContext('2d')!
      ctx.fillStyle = 'rgb(200,200,200)'
      ctx.fillRect(0, 0, cw, ch)
      ctx.fillStyle = 'rgba(150, 150, 150, 0.7)'
      ctx.drawImage(image, x, y, cw * v, ch * v)

      if (crop_x > 0) {
        ctx.fillRect(0, 0, crop_x, ch)
      }

      if (crop_x + crop_width < cw) {
        ctx.fillRect(crop_x + crop_width, 0, (cw - crop_x - crop_width), ch)
      }

      if (crop_height < ch) {
        ctx.fillRect(0, crop_height, cw, (ch - crop_height))
      }
    }
    drawCanvas()

    image_crop_canvas.removeEventListener("mousedown", mousedown)
    image_crop_canvas.addEventListener('mousedown', mousedown)

    function mousedown(e: MouseEvent) {
      isMouseDown = true
      mdx = e.pageX - x
      mdy = e.pageY - y
      return false
    }

    image_crop_canvas.removeEventListener("touchstart", touchstart)
    image_crop_canvas.addEventListener('touchstart', touchstart)
    function touchstart(e: TouchEvent) {
      e.preventDefault()
      isMouseDown = true
      mdx = e.changedTouches[0].pageX - x
      mdy = e.changedTouches[0].pageY - y
      return false
    }

    image_crop_canvas.removeEventListener("touchend", mouseup)
    image_crop_canvas.addEventListener('touchend', mouseup)
    image_crop_canvas.removeEventListener("mouseup", mouseup)
    image_crop_canvas.addEventListener('mouseup', mouseup)
    image_crop_canvas.removeEventListener("mouseout", mouseup)
    image_crop_canvas.addEventListener('mouseout', mouseup)
    function mouseup() {
      if (isMouseDown === false) return
      isMouseDown = false
      return false
    }

    image_crop_canvas.removeEventListener("mousemove", mousemove)
    image_crop_canvas.addEventListener('mousemove', mousemove)
    function mousemove(e: MouseEvent) {
      if (isMouseDown === false) return false
      if (e.pageY - mdy > 0) {
        y = 0
      } else {
        if (ch * v - crop_height + e.pageY - mdy <= 0) {
          y = crop_height - ch * v
        } else {
          y = (e.pageY - mdy)
        }
      }

      if ((cw - crop_width) / 2 - (e.pageX - mdx) <= 0) {
        x = (cw - crop_width) / 2
      } else {
        if ((cw - crop_width) / 2 + e.pageX - mdx + (cw * v - cw) <= 0) {
          x = 0 - (cw - crop_width) / 2 - (cw * v - cw)
        } else {
          x = (e.pageX - mdx)
        }
      }
      drawCanvas()

      return false
    }

    image_crop_canvas.removeEventListener("touchmove", touchmove)
    image_crop_canvas.addEventListener('touchmove', touchmove)
    function touchmove(e: TouchEvent) {
      e.preventDefault()
      if (isMouseDown === false) return

      if (e.changedTouches[0].pageY - mdy > 0) {
        y = 0
      } else {
        if (ch * v - crop_height + e.changedTouches[0].pageY - mdy <= 0) {
          y = crop_height - ch * v
        } else {
          y = (e.changedTouches[0].pageY - mdy)
        }
      }

      if ((cw - crop_width) / 2 - (e.changedTouches[0].pageX - mdx) <= 0) {
        x = (cw - crop_width) / 2
      } else {
        if ((cw - crop_width) / 2 + e.changedTouches[0].pageX - mdx + (cw * v - cw) <= 0) {
          x = 0 - (cw - crop_width) / 2 - (cw * v - cw)
        } else {
          x = (e.changedTouches[0].pageX - mdx)
        }
      }
      drawCanvas()
      return false
    }

    if (range) {
      $(range).off('change')
      $(range).on('change', function (e) {
        v = parseInt($(e.target).val()?.toString() ?? '0')
        drawCanvas()
      })
    }

    image_crop_canvas.removeEventListener("wheel", function () { return false; }, false)
    image_crop_canvas.addEventListener("wheel", function (e: WheelEvent) {
      e.stopPropagation()
      e.preventDefault()
      if (v + e.deltaY / 120 / 100 >= 1) {
        v += e.deltaY / 120 / 100
        if (range) {
          $(range).val(v)
        }
        drawCanvas()
      }
    })
  }
  image.src = image_for_crop

  const crop = () => {
    image_crop_canvas.setAttribute("width", crop_width.toString())
    image_crop_canvas.setAttribute("height", crop_height.toString())
    const ctx = image_crop_canvas.getContext('2d')!
    ctx.drawImage(image, x - crop_x, y, cw * v, ch * v)
    set_src(image_crop_canvas.toDataURL("image/png"))
    set_visible(false)
  }

  return (
    <div className={`fixed w-full h-full top-0 left-0 bg-black/75 z-10 ${visible ? 'flex' : 'hidden'} flex-col items-center`}>
      <div ref={menu_ref}>
        <div className="mt-3 flex justify-center items-center flex-row">
          <input
            type='button'
            className="cursor-pointer block py-2.5 px-4 rounded my-3 border border-solid border-white text-white bg-emerald-800 hover:bg-emerald-900"
            value={ t('Cancel')! }
            onClick={() => { set_visible(false) }} />

          <input
            type='button'
            className="cursor-pointer block py-2.5 px-4 rounded my-3 border border-solid border-white text-white bg-emerald-800 hover:bg-emerald-900 ml-5"
            value={ t('Crop')! }
            onClick={ crop } />
        </div>

        <input
          ref={range_ref}
          type='range'
          min='1'
          max='5'
          step='0.5'
          className='block w-11/12 mx-auto mt-3 cursor-pointer' />
      </div>

      <canvas
        className="block mt-5 cursor-pointer px-3"
        ref={canvas_ref}>
      </canvas>
    </div>
  )
}
