import React, {
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha"

// components
import Menu from './Menu'
import { Normal as ButtonNormal } from '../../storybook/Button/Normal/Normal'

interface Props {
  set_showing: (showing: string) => void,
  set_email: (email: string) => void,
}

export default function Authentication ({
  set_showing,
  set_email,
}: Props) {
  const { t } = useTranslation()

  // refs

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recaptcha_ref = useRef<any>(null)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const form_data = new FormData(e.currentTarget)
    const form_value = Object.fromEntries(form_data)
    const recaptcha_token = await recaptcha_ref.current.executeAsync()

    form_data.set('recaptcha_token', recaptcha_token)

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/reset_password`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      set_showing('validate')
      set_email(form_value['email'].toString())
    })
    .catch(() => {})
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        onSubmit={ submit }
        method="post"
      >
        <ReCAPTCHA
          ref={ recaptcha_ref }
          size="invisible"
          sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY! }
        />

        <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed text-center pb-1 mb-4">
          { t('Email') }
        </h2>
        <input
          type='email'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='email' />

        <ButtonNormal
          type='submit'
          content={ t('Submit') }
          classes='mx-auto mt-7'
        />
      </form>

      <Menu />
    </section>
  )
}
