import React, {
  useRef,
} from 'react'
import $ from 'jquery'

// types
interface ImageType {
  src: string,
  alt: string,
}

interface Props {
  images: ImageType[],
  classes: string,
}

export const Slideshow = ({
  images,
  classes,
}: Props) => {
  // refs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const images_ref = useRef<any[]>([])

  const slide_num_ref = useRef<HTMLParagraphElement | null>(null)

  const wrap = useRef(null)
  const ul = useRef(null)
  const prev = useRef(null)
  const next = useRef(null)
  const close = useRef(null)

  const progress_ref = useRef(null)
  const bar_ref = useRef(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const image_list: any[] = []

  images.map((image, index) => {
    images_ref.current[index] = React.createRef()
    image_list.push(images_ref.current[index])
  })

  let current_num = 1
  let percent = 0
  let timer: ReturnType<typeof setInterval> | undefined
  const timer_second = 30
  let is_pause = true
  const time = 0.7

  const reset_progressbar = () => {
    $(bar_ref.current!).css('width', '0%');
    percent = 0;
  }

  const clicked = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if(is_pause) {
      timer = setInterval(interval, timer_second)
      is_pause = false
    } else {
      clearTimeout(timer)
      is_pause = true
    }
  }

  const interval = () => {
    if(is_pause) {
      clearTimeout(timer)
      reset_progressbar()
    } else {
      percent += 1 / time;
      $(bar_ref.current!).css('width', percent + '%')
      if(percent >= 100) {
        moveRight()
        reset_progressbar()
      }
    }
  }

  const start = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    e.preventDefault();

    $(wrap.current!).removeClass('hidden')

    if(images.length > 1) {
      if (slide_num_ref.current) {
        $(slide_num_ref.current).html(current_num + '/' + images.length);
      }

      $(ul.current!).css('width', $(window).width()! * (images.length))
      $(ul.current!).css('transform', 'translateX(-' + $(window).width() + 'px)');
      $(image_list[images.length - 1].current).parent().insertBefore($(image_list[0].current).parent());
      const r = image_list.splice(image_list.length-1, 1)
      image_list.splice(0,0,r[0])
    } else {
      $(prev.current!).addClass('hidden')
      $(next.current!).addClass('hidden')
      $(progress_ref.current!).addClass('hidden')
      $(slide_num_ref.current!).addClass('hidden')
    }

    images_ref.current.map((v) => {
      $(v.current).parent().css({
        'height': $(window).height() + 'px',
        'width': $(window).width() + 'px',
      })
      if (($(v.current).parent().innerWidth()! * $(v.current).innerHeight()! / $(v.current).innerWidth()!) < $(v.current).parent().innerHeight()!) {
        $(v.current).css({
          "height": $(v.current).parent().innerWidth()! * $(v.current).innerHeight()! / $(v.current).innerWidth()! + "px",
          "width": $(v.current).parent().innerWidth()! + "px",
        });
      } else if (($(v.current).parent().innerHeight()! * $(v.current).innerWidth()! / $(v.current).innerHeight()!) < $(v.current).parent().innerWidth()!) {
        $(v.current).css({
          "width": $(v.current).parent().innerHeight()! * $(v.current).innerWidth()! / $(v.current).innerHeight()! + "px",
          "height": $(v.current).parent().innerHeight()! + "px",
        });
      } else {
        if (($(v.current).innerWidth()! < $(v.current).innerHeight()!) || ($(v.current).innerHeight()! > $(window).height()!)) {
          $(v.current).css({
            "height": $(v.current).parent().parent().innerHeight() + "px",
            "width": "auto",
          });
        } else {
          $(v.current).parent().children('img').eq(0).css({
            'width': $(window).width() + 'px',
          });
        }
      }
    })
  }

  const close_click = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    $(wrap.current!).addClass('hidden')
  }

  const moveLeft = () => {
    if (current_num === 1) {
      current_num = images.length;
    } else {
      current_num--;
    }
    if (slide_num_ref.current) {
      $(slide_num_ref.current).html(current_num + '/' + images.length);
    }
    $(prev.current!).css('pointer-events', 'none');
    $(next.current!).css('pointer-events', 'none');
    $(ul.current!).css('transition', '1s');
    $(ul.current!).css('transform', 'translateX(' + 0 + 'px)');
    setTimeout(() => {
      $(image_list[images.length - 1].current).parent().insertBefore($(image_list[0].current).parent());
      const r = image_list.splice(image_list.length-1, 1)
      image_list.splice(0,0,r[0])

      $(ul.current!).css('transition', 'none');
      $(ul.current!).css('transform', 'translateX(' + (-1) * $(window).width()! + 'px)');
      $(prev.current!).css('pointer-events', 'auto');
      $(next.current!).css('pointer-events', 'auto');
    }, 1000);
  }

  const moveRight2 = () => {
    $(image_list[0].current).parent().insertAfter($(image_list[images.length - 1].current).parent());

    const r = image_list.splice(0,1)

    image_list.splice(images.length - 1, 0, r[0])
    $(ul.current!).css({
      'transition': 'none',
      'transform': 'translateX(' + 0 + 'px)',
    });

    setTimeout(() => {
      $(ul.current!).css({
        'transition': '1s',
        'transform': 'translateX(' + (-1) * $(window).width()! + 'px)',
      });
    }, 10)
  }

  const moveRight = () => {
    if (current_num === images.length) {
      current_num = 1;
    } else {
      current_num++;
    }

    if (slide_num_ref.current) {
      $(slide_num_ref.current).html(current_num + '/' + images.length);
    }
    if (images.length > 2) {
      $(prev.current!).css('pointer-events', 'none');
      $(next.current!).css('pointer-events', 'none');

      $(ul.current!).css({
        'transition': '1s',
        'transform': 'translateX(' + (-2) * $(window).width()! + 'px)',
      })

      setTimeout(() => {
        $(image_list[0].current).parent().insertAfter($(image_list[images.length - 1].current).parent());
        const r = image_list.splice(0,1)
        image_list.splice(images.length - 1,0,r[0])

        $(ul.current!).css({
          'transition': 'none',
          'transform': 'translateX(' + (-1) * $(window).width()! + 'px)',
        })

        $(prev.current!).css('pointer-events', 'auto')
        $(next.current!).css('pointer-events', 'auto')
      }, 1000);
    } else {
      moveRight2();
    }
  }

  const prev_click = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    moveLeft();
    clearTimeout(timer)
    reset_progressbar()
    is_pause = true
  }

  const next_click = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    moveRight();
    clearTimeout(timer)
    reset_progressbar()
    is_pause = true
  }

  const list = images.map((image, index) => {
    const width_percent = 1/images.length * 100

    return (
      <li
        key={index}
        className='float-left block relative w-screen h-full'
        style={{ 'width': `${width_percent}%` }}
      >
        <img
          onClick={clicked}
          ref={images_ref.current[index]}
          className='absolute max-w-full m-0 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'
          src={image['src']}
          alt={image['alt']}
        />
      </li>
    )
  })

  return (
    <>
      <div
        ref={wrap}
        className='fixed top-0 left-0 w-full h-full z-30 bg-black/90 hidden'
      >
        <div
          onClick={close_click}
          ref={close}
          className='absolute p-2 z-20 top-8 left-8 flex justify-center items-center border border-black border-solid w-14 h-14 text-center bg-slate-200 text-amber-600 opacity-80 rounded-full cursor-pointer hover:opacity-100'
        >
          ×
        </div>

        <input
          onClick={prev_click}
          ref={prev}
          type='button'
          className='absolute p-2 z-20 top-1/2 -translate-y-1/2 left-8 flex justify-center items-center border border-black w-14 h-14 text-center bg-slate-200 text-amber-600 opacity-80 rounded-full cursor-pointer hover:opacity-100'
          value="<"
        />

        <input
          onClick={next_click}
          ref={next}
          type='button'
          className='absolute p-2 z-20 top-1/2 -translate-y-1/2 right-8 flex justify-center items-center border border-black w-14 h-14 text-center bg-slate-200 text-amber-600 opacity-80 rounded-full cursor-pointer hover:opacity-100' value=">"
        />

        <p
          ref={slide_num_ref}
          className='absolute bg-black/40 text-white py-2 px-3.5 z-30 left-1/2 bottom-8 -translate-x-1/2 text-center mt-3'
        ></p>

        <div
          className='absolute bg-black/30 h-14 w-full bottom-0 z-20'
          ref={progress_ref}
        >
          <div
            ref={bar_ref}
            className='h-full w-0 bg-white'
          ></div>
        </div>

        <div className='absolute w-full mx-auto overflow-x-hidden h-full'>
          <ul
            className='w-full h-full list-none'
            ref={ul}
          >
            {list}
          </ul>
        </div>
      </div>

      <img
        src={images[0]['src']}
        alt={images[0]['alt']}
        className={classes}
        onClick={start}
      />
    </>
  )
}
