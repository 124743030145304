
import React from 'react'

interface Props {
  theme?: string,
  type?: 'button' | 'submit' | 'reset',
  disabled?: boolean,
  content: string,
  on_click_action?: () => void,
  classes?: string,
}

export const Normal = ({
  theme = 'rose',
  type = 'button',
  disabled = false,
  content,
  on_click_action,
  classes,
}: Props) => {
  let color = ''

  switch (theme) {
    case 'sky':
      color = 'bg-sky-800 hover:bg-sky-900 text-white'
      break
    case 'emerald':
      color = 'bg-emerald-800 hover:bg-emerald-900 text-white'
      break
    default: //rose
      color = 'bg-rose-900 hover:bg-rose-900 text-white'
      break
  }

  const style = `cursor-pointer border border-solid border-black dark:border-white rounded block px-6 py-3 shadow-black shadow active:translate-y-0.5 active:shadow-none ${color} ${classes}`

  return (
    <button
      type={type}
      className={style}
      disabled={disabled}
      onClick={ () => {
        if (on_click_action) {
          on_click_action()
        }
      } }
    >
      { content }
    </button>
  )
}
