
import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { Item } from '../../storybook/Aside/Item/Item';

interface Props {
  is_mobile?: boolean,
}

export const Functions = ({
  is_mobile = false,
}: Props) => {
  const { t, i18n } = useTranslation()

  const apps = [
    {
      'name': t('Call'),
      'path': `/${i18n.language}`,
    },
  ]

  return apps.map((app, index) => {
    return (
      <Item
        key={ index }
        name={ app['name'] }
        path={ app['path'] }
        theme='rose'
        is_mobile={ is_mobile }
      />
    )
  })
}