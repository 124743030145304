import React, {
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'
import { Link } from 'react-router-dom'

// redux
import { useAppSelector } from '../app/hooks'

import { custom_axios } from '../axios'

export function Index() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  // redux
  const user = useAppSelector(state => state.user.user)

  // states
  const [age, set_age] = useState<string>('35')

  const on_submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form_data = new FormData(e.currentTarget)

    type ResponseType = {
      data: {
        room_id: string,
        video: boolean,
      }
    }

    await custom_axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/rooms/api/start`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: ResponseType) => {
      navigate(`/${i18n.language}/call?room_id=${response.data.room_id}&video=${response.data.video}`)
    })
  }

  if (!user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <Link
          to={`/${i18n.language}/login`}
          className="text-fuchsia-900 dark:text-fuchsia-300 hover:text-fuchsia-950 dark:hover:text-fuchsia-500 cursor-pointer"
        >
          { t('Please login first') }
        </Link>
      </section>
    )
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        onSubmit={ on_submit }
      >
        <h2 className="text-blue-800 dark:text-blue-400 border-t-0 border-r-8 border-l-8 border-b border-blue-800 dark:border-blue-400 border-solid text-center mb-4">
          {t('Your data')}
        </h2>

        <h2 className="text-pink-800 dark:text-pink-400 mb-2">{t('Sex')}</h2>

        <select
          required
          name="sex"
          defaultValue={user?.sex}
          className="block text-center bg-white text-black p-2 border border-solid border-black w-full"
        >
          <option value='male'>{t('Male')}</option>
          <option value='female'>{t('Female')}</option>
        </select>

        <h2 className="text-pink-800 dark:text-pink-400 mt-3 mb-2">{t('Birth')}</h2>

        <input
          required
          type='date'
          name="birth"
          defaultValue={user?.date_of_birth}
          className="w-full block border border-gray-400 p-2.5 mx-auto text-center border-solid bg-white text-black"
        />

        <h2 className="text-blue-800 dark:text-blue-400 border-t-0 border-r-8 border-l-8 border-b border-blue-800 dark:border-blue-400 border-solid text-center mt-6 mb-4">
          {t('Call to')}
        </h2>

        <h2 className="text-pink-800 dark:text-pink-400 mb-2">{t('Sex')}</h2>
        <select
          required
          name="friend_sex"
          className="block text-center bg-white text-black p-2 border border-solid border-black w-full"
        >
          <option value='all'>{t('All')}</option>
          <option value='male'>{t('Male')}</option >
          <option value='female'>{t('Female')}</option >
        </select >

        <h2 className="text-pink-800 dark:text-pink-400 mt-3 mb-2">{t('Age range')}</h2>

        <div>0 - {age}</div>
        <input
          type="range"
          required
          name="friend_age_max"
          min="0"
          max="120"
          className="block w-full"
          value={age}
          onChange={ (e) => { set_age(e.currentTarget.value) } }
        />

        <h2 className="text-pink-800 dark:text-pink-400 mt-3 mb-2">{t('Language')}</h2>
        <select
          required
          name="language"
          className="block text-center bg-white text-black p-2 border border-solid border-black w-full"
        >
          <option value='ja'>日本語</option>
          <option value='en' > English</option >
          <option value='cn' > 中文</option >
        </select >

        <div className="flex flex-row flex-wrap items-center mt-3">
          <h2 className="text-pink-800 dark:text-pink-400">{t('Video call')}</h2>
          <input
            type="checkbox"
            name="video"
            className="block ml-2 w-8 h-8 cursor-pointer"
          />
        </div>

        <input
          type='submit'
          className="bg-sky-800 dark:bg-sky-400 hover:bg-sky-900 dark:hover:bg-sky-500 border-black border rounded block px-6 py-3 mx-auto mb-8 mt-8 cursor-pointer text-white shadow-black shadow active:translate-y-0.5 active:shadow-none"
          value={ t('Call') }
        />
      </form >
    </section >
  )
}
