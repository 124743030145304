
import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

// components
import { Index as ResetPasswordIndex } from '../features/ResetPassword/Index'
import { Index as SignUpIndex } from '../features/SignUp/Index'
import { Login } from '../features/Login'

import { Index } from '../features/Index'
import { Call } from '../features/Call'

import { PrivacyPolicy } from '../features/PrivacyPolicy'
import { Tos } from '../features/Tos'

import { Index as SettingIndex } from '../features/Setting/Index'
import { Icon as SettingIcon } from '../features/Setting/Icon'
import { Password as SettingPassword } from '../features/Setting/Password'
import { Username as SettingUsername } from '../features/Setting/Username'

const Main = () => {
  return (
    <main className="relative bg-slate-200 dark:bg-main-bg-dark rounded w-full flex-grow">
      <Routes>
        <Route path='/reset_password' element={ <ResetPasswordIndex /> } />
        <Route path='/sign_up' element={ <SignUpIndex /> } />
        <Route path='/login' element={ <Login /> } />

        <Route path='/' element={ <Index /> } />
        <Route path='/call' element={ <Call /> } />

        <Route path='/privacy_policy' element={ <PrivacyPolicy /> } />
        <Route path='/tos' element={ <Tos /> } />

        <Route path='/setting' element={ <SettingIndex /> } />
        <Route path='/setting/icon' element={ <SettingIcon /> } />
        <Route path='/setting/password' element={ <SettingPassword /> } />
        <Route path='/setting/username' element={ <SettingUsername /> } />
      </Routes>
    </main>
  )
}

export default Main